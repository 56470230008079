export const ICONS = {
    folder: 'folder',
    asset: 'box',
    device: 'microchip',
    deviceconfigprofile: 'microchip',
    assettag: 'tags',
    simcard: 'mobile-alt',
    fuelcard: 'credit-card',
    deviceprovider: 'plug',
    devicebitmaps: 'binary',
    iotype: 'oil-temp',
    label: 'tag',
    costcentre: 'sitemap',
    assetgroup: 'cubes',
    assetcategory: 'tags',
    zonegroup: 'atlas',
    user: 'user',
    userrole: 'user-lock',
    policy: 'key',
    mapset: 'map',
    smsgateway: 'comments',
    exporttask: 'cloud-download-alt',
    task: 'tasks',
    media: 'photo-video',
    dashboard: 'analytics',
    dashboardtemplate: 'file-chart-line',
    widget: 'tachometer',
    scheduledreport: 'calendar',
    assetratingprofile: 'star',
    assetstateprofile: 'lightbulb',
    overspeedprofile: 'tachometer-alt-fast',
    geolockprofile: 'lock',
    roadprofile: 'road',
    reminderprofile: 'bell',
    customfield: 'book-open',
    sslcertificate: 'file-certificate',
    systemhealth: 'heartbeat',
    email: 'at',
    companygroup: 'folder', // TODO: get a better icon

    zone: 'map-marked-alt',
    alert: 'exclamation-triangle',

    theme: 'palette',
    analyticsbasereport: 'table',

};

export const COMPANY_ICONS = {
    system: 'landmark',
    distributor: 'city',
    vendor: 'building',
    client: 'home',
};

export const ASSET_ICONS = {
    aircraft: 'plane',
    container: 'container-storage',
    driver: 'steering-wheel',
    fixedasset: 'tv',
    person: 'user-circle',
    ship: 'ship',
    site: 'warehouse-alt',
    trailer: 'truck-container',
    vehicle: 'truck',
};
