import { Component, Output, EventEmitter, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { debounce } from 'lodash';
import { IKuiDropdownMenuItem } from 'app/key-ui';
import { ClientResponse } from 'app/shared/graphql';
import { AppService } from 'app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeHtml } from '@angular/platform-browser';

export const ALL_SHIFTS_ID = '__ALL__SHIFTS__';

export interface DateSelectionChange {
    date: string;
    shiftIndex: number;
}

@Component({
    selector: 'key-date-select',
    templateUrl: './date-selection.component.html',
})
export class KeyDateSelectComponent implements OnChanges, OnInit {

    @Input() currentString: string;
    @Input() date: string;
    @Input() minDate: string;
    @Input() clearButtonArgument: string;
    @Input() addDayToNextDayCheck: boolean;
    @Input() fireDebounceLeadingEdge = true;
    @Input() selectedShiftIndex: number;
    @Output() onDateSelection = new EventEmitter<DateSelectionChange>();

    localDate: string;
    maxDate: string = moment.utc().startOf('day').toISOString(); // NOTE: We don't support future dates at the moment, cause why would you want to select a date in the future anyways??!!??
    debounceEmitDateSelect;
    client: ClientResponse;
    shiftsMenu: IKuiDropdownMenuItem[];
    selectedShiftName: string | SafeHtml;
    shiftsEnabled: boolean;
    dateOffset = 0;

    get hasNextDay(): boolean {
        // add a day if the input date is the start of the date range rather than the end
        let endDate = moment.utc(this.localDate);
        if (this.addDayToNextDayCheck) {
            endDate = endDate.add(1, 'days');
        }
        return this.localDate && moment.utc().startOf('day').diff(endDate) > 0;
    }

    get hasPrevDay(): boolean {
        return this.minDate ? this.localDate && moment.utc(this.minDate).startOf('day').diff(this.localDate) < 0 : true;
    }

    constructor(
        private app: AppService,
        private i18n: TranslateService
    ) {
    }

    async ngOnChanges(changes: SimpleChanges) {

        if (changes.date) {
            this.localDate = changes.date.currentValue;
        }

        if (changes.selectedShiftIndex) {
            this.shiftsEnabled = !!(this.selectedShiftIndex || this.selectedShiftIndex === 0) && this.app.features.shifts.shifts.enabled;
        }
        
        if (this.shiftsEnabled && !this.client) {
            try {
                this.client = await this.app.api.accounts.getClient(this.app.client.id);
                this.selectedShiftIndex = this.selectedShiftIndex || 0;
                this.shiftsMenu = [{
                    id: ALL_SHIFTS_ID,
                    text: this.i18n.instant('SHARED.DATE_SELECT.ALL_SHIFTS'),
                    type: 'action',
                    closeDropdownOnClicked: true,
                    action: () => this.doShiftSelection(0),
                }];

                this.shiftsMenu.push(
                    ...(this.client.shiftPattern && this.client.shiftPattern.dailyShift || []).map((x, i) => ({
                        id: x.id,
                        type: 'action',
                        closeDropdownOnClicked: true,
                        text: x.name,
                        action: () => this.doShiftSelection(i + 1),
                    }))
                );
                this.selectedShiftName = this.shiftsMenu[this.selectedShiftIndex].text;
            } catch (error) {
                console.log(error);
            }
        }
    }

    ngOnInit() {
        this.debounceEmitDateSelect = debounce(this.emitDateSelect, this.fireDebounceLeadingEdge ? 500 : 250, { leading: this.fireDebounceLeadingEdge });
    }
    
    setShiftIndexAndName(index: number) {
        this.selectedShiftIndex = index;
        this.selectedShiftName = this.shiftsMenu[index].text;
    }

    doShiftSelection(index: number) {
        this.setShiftIndexAndName(index);
        this.emitDateSelect();
    }

    doDateSelection(date: string) {
        if (date === 'today' || date === 'now') {
            if (this.shiftsEnabled) {
                this.setShiftIndexAndName(0); // select all shifts on reset
            }
            date = moment.utc().toISOString();
        }
        this.dateOffset = this.getDateOffset(date);
        this.localDate = moment(date).utc().add(this.dateOffset, 'minutes').startOf('day').toISOString();
        this.debounceEmitDateSelect();
    }

    emitDateSelect() {
        this.onDateSelection.emit({
            date: this.localDate,
            shiftIndex: this.shiftsEnabled ? this.selectedShiftIndex : null,
        });
    }

    selectPreviousDate() {
        let date;

        if (this.localDate) {
            date = moment(this.localDate).startOf('day').subtract(1, 'days').toISOString();
        } else {
            date = moment.utc().toISOString();
        }
        this.doDateSelection(date);
    }

    selectNextDate() {
        if (this.localDate && this.hasNextDay) {
            const date = moment.utc(this.localDate).add(1, 'days').toISOString();
            this.doDateSelection(date);
        }
    }

    getDateOffset(date: string): number {
        return date ? moment(date).utcOffset() : 0; 
    }
}
