import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';

export interface MeasurementUnitsUnits {
    distanceUnit?: string;
    altitudeUnit?: string;
    speedUnit?: string;
    areaUnit?: string;
    volumeUnit?: string;
    weightUnit?: string;
    dateUnit?: string;
    temperatureUnit?: string;
    timeUnit?: string;
}

export interface MeasurementDateTimeConfigItem {
    long?: string;
    short?: string;
}

export interface MeasurementUnitsConfigItem extends MeasurementDateTimeConfigItem {
    multiplier?: number;
    unit: string;
    symbol?: string;
}

export interface MeasurementUnitsConfig {
    units?: { [key: string]: MeasurementUnitsConfigItem };
    value?: (raw: number | string | Moment, unit: MeasurementUnitsConfigItem) => number | Moment;
    format?: (value: number | Moment, unit: MeasurementUnitsConfigItem, variant?: string) => string;
    toBackend?: (value: number | Moment | string, unit: MeasurementUnitsConfigItem) => number | string;
    fromBackend?: (value: number, unit: MeasurementUnitsConfigItem, roundingDigits?: number) => number;
    error?: string;
}

export type MeasurementUnitType = 'distance' | 'altitude' | 'speed' | 'area' | 'volume' | 'weight' | 'date' | 'temperature' | 'time';

export const MeasurementUnits: { [key: string]: MeasurementUnitType } = {
    DISTANCE: 'distance',
    ALTITUDE: 'altitude',
    SPEED: 'speed',
    AREA: 'area',
    VOLUME: 'volume',
    WEIGHT: 'weight',
    DATE: 'date',
    TEMPERATURE: 'temperature',
    TIME: 'time',
};

@Injectable()
export class MeasurementUnitsService {
    private _defaultConfig: MeasurementUnitsUnits = {
        altitudeUnit: 'feet',
        areaUnit: 'squareKilometres',
        dateUnit: 'dmyyyy2',
        distanceUnit: 'miles',
        speedUnit: 'milesPerHour',
        temperatureUnit: 'celsius',
        timeUnit: 'HHmmss',
        volumeUnit: 'gallons',
        weightUnit: 'kilograms',
    };

    config: MeasurementUnitsUnits;

    units: { [key: string]: any };

    constructor(private app: AppService) {

        this.config = this._defaultConfig;
        if (this.app && this.app.client$) {
            this.app.client$.subscribe(client => {
                if (client) {
                    this.config = client.measurementUnits ? { ...this._defaultConfig, ...client.measurementUnits } : this._defaultConfig; // TODO: test that measurement units are being correctly populated
                } else {
                    this.config = this._defaultConfig;
                }
            });
        }

    }

    format(raw: number | string, type: string, variant?: string): { value: number | Moment, format: string, unit: string, multiplier: number } {
        const list = this.getUnitList(type);
        const configValue = this.getUnitConfig(type);

        if ((!raw && raw !== 0) || !type || list.error) {
            return { value: null, format: '', unit: '', multiplier: 1 };
        }

        const unit: MeasurementUnitsConfigItem = list['units'][configValue];
        const value = list.value ? list.value(raw, unit) : +raw / unit.multiplier;
        const format = list.format ? list.format(value, unit, variant) : `${(value as number).toFixed(2)} ${unit.symbol}`;

        return { value, format, unit: unit.symbol, multiplier: unit.multiplier };
    }

    unit(type: string): string {
        const list = this.getUnitList(type);
        const configValue = this.getUnitConfig(type);
        return list.error ? undefined : list.units[configValue].unit;
    }

    unitSymbol(type: string): string {
        const list = this.getUnitList(type);
        const configValue = this.getUnitConfig(type);
        return list.error ? undefined : list.units[configValue].symbol;
    }

    getUnitConfig(type?: string): string {
        return this.config[`${type}Unit`];
    }

    getUnitList(type?: string): MeasurementUnitsConfig {
        switch (type) {
            case MeasurementUnits.DISTANCE:
                return {
                    units: {
                        metres: { multiplier: 0.001, unit: 'metres', symbol: 'm' },
                        kilometres: { multiplier: 1, unit: 'kilometres', symbol: 'km' },
                        feet: { multiplier: 0.0003048, unit: 'feet', symbol: 'ft' },
                        inches: { multiplier: 0.0000254, unit: 'inches', symbol: 'in' },
                        miles: { multiplier: 1.609344, unit: 'miles', symbol: 'mi' },
                        nauticalMiles: { multiplier: 1.852, unit: 'nautical miles', symbol: 'nmi' },
                        yards: { multiplier: 0.0009144, unit: 'yards', symbol: 'yd' },
                    },
                };
            case MeasurementUnits.ALTITUDE:
                return {
                    units: {
                        metres: { multiplier: 0.001, unit: 'metres', symbol: 'm' },
                        kilometres: { multiplier: 1, unit: 'kilometres', symbol: 'km' },
                        feet: { multiplier: 0.0003048, unit: 'feet', symbol: 'ft' },
                        inches: { multiplier: 0.0000254, unit: 'inches', symbol: 'in' },
                        miles: { multiplier: 1.609344, unit: 'miles', symbol: 'mi' },
                        nauticalMiles: { multiplier: 1.852, unit: 'nautical miles', symbol: 'nmi' },
                        yards: { multiplier: 0.0009144, unit: 'yards', symbol: 'yd' },
                    },
                    value: (raw: number, unit): number => raw / (unit.multiplier * 1000),
                };
            case MeasurementUnits.SPEED:
                return {
                    units: {
                        metresPerSecond: { multiplier: 3.6, unit: 'metres per second', symbol: 'm/s' },
                        kilometresPerHour: { multiplier: 1, unit: 'kilometres per hour', symbol: 'km/h' },
                        milesPerHour: { multiplier: 1.609344, unit: 'miles per hour', symbol: 'mph' },
                        knots: { multiplier: 1.85200, unit: 'knots', symbol: 'kn' },
                    },
                    value: (raw: number, unit): number => Math.round(raw / unit.multiplier),
                    format: (value: number, unit) => `${value.toFixed(0)} ${unit.symbol}`,
                };
            case MeasurementUnits.AREA:
                return {
                    units: {
                        acres: { multiplier: 0.00404685642, unit: 'acres', symbol: 'ac' },
                        hectares: { multiplier: 0.01, unit: 'hectares', symbol: 'ha' },
                        squareFeet: { multiplier: 0.00000009290304, unit: 'square feet', symbol: 'ft2' },
                        squareInches: { multiplier: 0.00000000064516, unit: 'square inches', symbol: 'in2' },
                        squareKilometres: { multiplier: 1, unit: 'square kilometres', symbol: 'km2' },
                        squaremetres: { multiplier: 0.000001, unit: 'square metres', symbol: 'm2' },
                        squareMiles: { multiplier: 2.58998811, unit: 'square miles', symbol: 'mi2' },
                        squareYards: { multiplier: 0.00000083612736, unit: 'square yards', symbol: 'yd2' },
                    },
                };
            case MeasurementUnits.VOLUME:
                return {
                    units: {
                        litres: { multiplier: 1, unit: 'litres', symbol: 'l' },
                        cubicmetres: { multiplier: 1000, unit: 'cubic metres', symbol: 'm3' },
                        cubicFeet: { multiplier: 28.3168466, unit: 'cubic feet', symbol: 'ft3' },
                        gallons: { multiplier: 4.54609, unit: 'gallons', symbol: 'gal' },
                        gallonsUs: { multiplier: 3.78541178, unit: 'gallons', symbol: 'gal' },
                        ounces: { multiplier: 0.0284131, unit: 'fluid ounce', symbol: 'fl oz' },
                        ouncesUs: { multiplier: 0.0295735296, unit: 'fluid ounce', symbol: 'fl oz' },
                    },
                };
            case MeasurementUnits.WEIGHT:
                return {
                    units: {
                        kilograms: { multiplier: 1, unit: 'kilograms', symbol: 'kg' },
                        ounces: { multiplier: 0.0283495231, unit: 'ounces', symbol: 'oz' },
                        pounds: { multiplier: 0.45359237, unit: 'pounds', symbol: 'lb' },
                        tonnes: { multiplier: 1000, unit: 'tonnes', symbol: 't' },
                    },
                };
            case MeasurementUnits.TEMPERATURE:
                return {
                    units: {
                        celsius: { unit: 'celsius', symbol: '\u00B0C' },
                        fahrenheit: { unit: 'fahrenheit', symbol: '\u00B0F' },
                    },
                    value: (raw: number, unit): number => unit.unit === 'fahrenheit' ? Math.round(((raw * 1.8) + 32) * 10) / 10 : raw,
                    format: (value: number, unit) => `${value.toFixed(1)} ${unit.symbol}`,
                    toBackend: (value: number, unit: MeasurementUnitsConfigItem) => unit.unit === 'fahrenheit' ? (value - 32) / 1.8 : value,
                    fromBackend: (raw: number, unit, roundingDigits: number): number => unit.unit === 'fahrenheit' ? Math.round(((raw * 1.8) + 32) * (Math.pow(10, roundingDigits))) / (Math.pow(10, roundingDigits)) : raw,
                };
            case MeasurementUnits.DATE:
                return {
                    units: {
                        yyyymmdd1: { unit: 'YYYY/MM/DD', long: 'dddd, D MMMM YYYY' },
                        yyyymmdd2: { unit: 'YYYY.MM.DD', long: 'dddd, D MMMM YYYY' },
                        yyyymmdd3: { unit: 'YYYY-MM-DD', long: 'dddd, D MMMM YYYY' },
                        ddmmyyyy1: { unit: 'DD/MM/YYYY', long: 'dddd, D MMMM YYYY' },
                        ddmmyyyy2: { unit: 'DD.MM.YYYY', long: 'dddd, D MMMM YYYY' },
                        ddmmyyyy3: { unit: 'DD-MM-YYYY', long: 'dddd, D MMMM YYYY' },
                        dmyyyy1: { unit: 'D/M/YYYY', long: 'dddd, D MMMM YYYY' },
                        dmyyyy2: { unit: 'D.M.YYYY', long: 'dddd, D MMMM YYYY' },
                        dmyyyy3: { unit: 'D-M-YYYY', long: 'dddd, D MMMM YYYY' },
                        mmddyyyy1: { unit: 'MM/DD/YYYY', long: 'dddd, MMMM D, YYYY' },
                        mdyyyy1: { unit: 'M/D/YYYY', long: 'dddd, MMMM D, YYYY' },
                    },
                    value: (raw: string | Moment): Moment => {
                        if (moment.isMoment(raw)) {
                            return raw as Moment;
                        } else {
                            if (moment().zoneName()) {
                                return moment(raw);
                            } else {
                                return moment.utc(raw);
                            }
                        }
                    },
                    format: (value: Moment, { unit, long }, variant) => value.format(variant === 'long' ? long : unit),
                    toBackend: (value: Moment): string => {
                        const val = moment.isMoment(value) ? value : moment.utc(value).tz(moment().zoneName() || 'UTC');
                        return val.toISOString();
                    },
                };
            case MeasurementUnits.TIME:
                return {
                    units: {
                        HHmmss: { unit: 'HH:mm:ss', short: 'HH:mm' },
                        Hmmss: { unit: 'H:mm:ss', short: 'H:mm' },
                        hmmssa: { unit: 'h:mm:ss A', short: 'h:mm A' },
                    },
                    value: (raw: string | Moment): Moment => {
                        if (moment.isMoment(raw)) {
                            return raw as Moment;
                        } else {
                            if (moment().zoneName()) {
                                return moment(raw);
                            } else {
                                return moment.utc(raw);
                            }
                        }
                    },
                    format: (value: Moment, { unit, short }, variant) => value.format(variant === 'short' ? short : unit),
                    toBackend: (value: Moment): string => {
                        // FIXME: this is all kinds of wrong... for one you are expected to pass it a moment item and seen as it is time that would not always be possible
                        let val = value;
                        if (!moment.isMoment(value)) {
                            if (moment().zoneName()) {
                                val = moment(value);
                            } else {
                                val = moment.utc(value);
                            }
                        }
                        return val.toISOString();
                    },
                };
            default:
                return {
                    error: 'ERROR: Unit does not exist',
                };
        }
    }

    fromBackend(type: MeasurementUnitType, value: number, roundingDigits?: number): number {
        const list = this.getUnitList(type);
        const configValue = this.getUnitConfig(type);
        const unit: MeasurementUnitsConfigItem = list['units'][configValue];

        if (list.fromBackend) {
            // FIXME: this might not work for time units
            return list.fromBackend(value, unit, roundingDigits);
        }

        // FIXME: this isn't going to work for everything. dates & temperature come to mind. this whole class needs a makeover
        let val = value / unit.multiplier;
        if (roundingDigits === 0) {
            val = Math.round(val);
        }
        if (roundingDigits > 0) {
            val = Math.round(val * (Math.pow(10, roundingDigits))) / Math.pow(10, roundingDigits);
        }
        return val;
    }

    toBackend(type: MeasurementUnitType, value: number, roundingDigits?: number): string | number {
        const list = this.getUnitList(type);
        const configValue = this.getUnitConfig(type);
        const unit: MeasurementUnitsConfigItem = list['units'][configValue];

        if (list.toBackend) {
            // FIXME: this might not work for time units
            return list.toBackend(value, unit);
        }

        // if not number the result will always return NaN so rather assume that you want to leave the value unchanged
        if (typeof value !== 'number') {
            return value;
        }

        // FIXME: this whole class needs a makeover
        let val = value * unit.multiplier;
        if (roundingDigits === 0) {
            val = Math.round(val);
        }
        if (roundingDigits > 0) {
            val = Math.round(val * (Math.pow(10, roundingDigits))) / Math.pow(10, roundingDigits);
        }
        return val;
    }

}
